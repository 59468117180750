import * as toggles from '../../toggles';
import * as ElchspuckeService from '../../elchspucke-tuicom.service';
import {
    applyPageData,
    injectResponsiveCoupon,
    renderMobileTeaserInHotelList,
    renderMobileTeaserInLandingPage
} from './responsive';
import * as service from './service';

/**********************************************************/
/* Initialize coupon-controller ***************************/
/**********************************************************/
export default function (config) {
    if (!toggles.isActive('coupons')) {
        return false;
    }

    let couponElement = undefined;

    const initializeCoupon = (pageData) => {
        loadCoupon(pageData)
            .then(() => {
                if (couponElement && pageData?.ibeSearchStep?.hotelList && service.isMobileBreakpoint()) {
                    // Render the mobile coupon teasers in the hotellist on the PTH3 page
                    // where multiple instances of one certain coupon are displayed
                    renderMobileTeaserInHotelList(couponElement);
                } else if (couponElement && pageData?.pageType?.editorial && service.isMobileBreakpoint()) {
                    // Render the mobile coupon teasers in a landingPage
                    // where multiple cottonBall events are used to gather data and to inject the coupon
                    renderMobileTeaserInLandingPage(couponElement, pageData);
                } else {
                    // Create a new couponElement
                    couponElement = injectResponsiveCoupon({
                        language: ElchspuckeService.getPageLocale(),
                        tenant: config.getTenant(),
                        pageData
                    });
                }
            })
            .then(() => applyPageData(pageData))
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error('Coupon: Error occured in initializeCoupon', error);
            });
    };

    if (!document.getElementById('coupon-script')) {
        const $componentJs = document.createElement('script');
        $componentJs.setAttribute('src', `${config.getAWSCdnUrl()}coupon/v2/tui-coupon.js`);
        $componentJs.setAttribute('type', 'module');
        $componentJs.setAttribute('id', 'coupon-script');

        $componentJs.onerror = (error) => console.error('Coupon script could not be loaded', error);

        document.body.appendChild($componentJs);
    }

    const isMfeReady = customElements.whenDefined('tui-coupon');

    const loadCoupon = (pageData) =>
        new Promise((resolve, reject) => {
            if (document.querySelector('tui-coupon')) {
                return resolve();
            }

            isMfeReady
                .then(() => {
                    couponElement = injectResponsiveCoupon({
                        language: ElchspuckeService.getPageLocale(),
                        tenant: config.getTenant(),
                        pageData
                    });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('injectResponsiveCoupon failed', error);

                    window.utag.link({
                        link_type: window.location.href,
                        link_category: 'no-coupon-mfe-loaded-script',
                        link_action: document.querySelector('.book-form') ? 'bu' : 'ibe',
                        link_label: JSON.stringify(pageData),
                        noninteraction: false
                    });

                    reject();
                });

            document.addEventListener('tui-coupon.Rendered', ({ detail: { cookieLastSeen } }) => {
                service.setLastSeenCoupon(cookieLastSeen);
                indicatedFlyInTracking();
            });

            document.addEventListener('tui-coupon.Initialized', resolve);
        });

    const utagReady = new Promise((resolve, reject) => {
        let counter = 0;
        const utagInterval = setInterval(() => {
            counter++;
            if (window.utag_data?.page_name !== 'IBE') {
                clearInterval(utagInterval);
                return resolve();
            }
            if (counter > 200) {
                clearInterval(utagInterval);
                reject();
            }
        }, 100);
    });

    const pageReady = new Promise((resolve) => {
        if (document.readyState === 'complete') {
            resolve();
        } else {
            document.addEventListener('readystatechange', () => {
                if (document.readyState === 'complete') {
                    resolve();
                }
            });
        }
    });

    // Determine if the page appears to be a Landingpage.
    // We borrowed this logic from the legacy coupon.
    const isLandingPage = () =>
        (document.querySelector('[ng-controller="HomesearchIbeController"]') ||
            document.querySelector('[class="cs cs--ibe / row ng-scope cs--sp"]')) !== null;

    // This is needed for tui.at, since there is no 'IBE search result rendered.' event
    window.tuiCottonBall.subscribe('tui-coupons', '*', 'Finished initializing the angular app.', (c, s, e, data) => {
        if (config.getTenant() !== 'TUIAT') {
            // For all tenants other than tui.at we should not initialize the coupons here
            // because we lack crucial information on region and country at this point.
            return;
        }

        window.couponTracked = false;
        Promise.all([utagReady, pageReady]).then(() => initializeCoupon(service.getPageData(data)));
    });

    window.tuiCottonBall.subscribe('tui-coupons', '*', 'IBE search result rendered.', (c, s, e, data) => {
        window.couponTracked = false;
        Promise.all([utagReady, pageReady]).then(() => initializeCoupon(service.getPageData(data)));
    });

    window.tuiCottonBall.subscribe('tui-coupons', '*', 'The search parameters did change.', () => {
        // Since we need to know if we are on a LandingPage currently
        // we do this dirty workaround
        if (isLandingPage()) {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeStateData) => {
                Promise.all([utagReady, pageReady]).then(() => initializeCoupon(service.getPageData(ibeStateData)));
            });
        }
    });

    window.tuiCottonBall.subscribe('tui-coupons', '*', 'search result rendered.', () => {
        // Since we need to know if we are on a LandingPage currently
        // we do this dirty workaround
        if (isLandingPage()) {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeStateData) => {
                Promise.all([utagReady, pageReady]).then(() => initializeCoupon(service.getPageData(ibeStateData)));
            });
        }
    });

    let buCouponInitialized = false;

    window.tuiCottonBall.subscribe('tui-coupons', '*', 'IBE checkout page rendered.', (c, s, e, data) => {
        Promise.all([utagReady, pageReady])
            .then(() => {
                const pageData = service.getPageData(data.data);
                initializeCoupon(pageData);

                buCouponInitialized = true;

                /* START HEALING COUPON ON BU */
                window.setTimeout(() => {
                    if (!document.querySelector('tui-coupon')) {
                        window.utag.link({
                            link_type: window.location.href,
                            link_category: 'no-coupon-mfe-loaded-timeout',
                            link_action: 'bu',
                            link_label: JSON.stringify(pageData),
                            noninteraction: false
                        });

                        injectResponsiveCoupon({
                            language: ElchspuckeService.getPageLocale(),
                            tenant: config.getTenant(),
                            pageData
                        });
                    }
                }, 3000);
                /* END HEALING COUPON ON BU */
            })
            .catch(() => {
                // eslint-disable-next-line no-console
                console.error('Coupon: Utag not ready');
                window.utag.link({
                    link_type: window.location.href,
                    link_category: 'no-coupon-mfe-utag-error',
                    link_action: 'bu',
                    link_label: JSON.stringify(service.getPageData(data.data)),
                    noninteraction: false
                });
            });
    });

    // Only on BU / Checkout Page
    // Wait for dom ready and check if coupon was initialized.
    // If not coupon will initialized.
    // Reason: Race condition cottonball events vs mojo. The event above could be thrown before
    // mojo was loaded.
    Promise.all([utagReady, pageReady]).then(() => {
        // Wait again two seconds to give the cottonball a chance to appear.
        setTimeout(() => {
            if (!buCouponInitialized && window.location.href.includes('/checkout')) {
                const pageData = service.getPageData();
                initializeCoupon(pageData);

                buCouponInitialized = true;

                window.utag.link({
                    link_type: window.location.href,
                    link_category: 'no-coupon-event-fired-yet',
                    link_action: 'bu',
                    link_label: null,
                    noninteraction: false
                });
            }
        }, 2000);
    });

    const indicatedFlyInTracking = () => {
        const mastercode = document.querySelector('tui-coupon')?.shadowRoot?.querySelector('tui-coupon-container')?.getAttribute('mastercode');
        if (window.location.href.includes('/checkout') && mastercode) {
            window.utag.link({
                link_type: 'flyin',
                link_category: 'promocode',
                link_action: 'indicated',
                link_label: mastercode
            });
        }
    };

    window.tuiCottonBall.subscribe('tui-coupons', '*', 'IBE search result pagination', () => {
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeStateData) => {
            Promise.all([utagReady, pageReady]).then(() => initializeCoupon(service.getPageData(ibeStateData)));
        });
    });

    window.tuiCottonBall.subscribe('tui-coupons', '*', 'IBE checkout confirmation page rendered.', () => {
        service.trackRedeemedCoupon(config);
    });

    Promise.all([utagReady, pageReady]).then(() => {
        if (window.utag_data.page_name?.startsWith('deals')) {
            initializeCoupon(service.getPageData({}));
        }
    });

    /* Compatibility events START */

    window.tuiCottonBall.subscribe('coupons', '*', 'get me the current offer coupon.', (c, s, e, eventData) => {
        if (!eventData.success || typeof eventData.success !== 'function') {
            return false;
        }

        const pageData = service.getPageData(eventData.data, undefined, true);

        const couponServiceUrl = config.getCouponServiceUrl();

        const overwrites = service.getPreviewModeData();

        if (pageData && overwrites?.pageData?.audienceBadges?.length) {
            pageData.audienceBadges = overwrites.pageData.audienceBadges;
        }

        window
            .fetch(`${couponServiceUrl}/coupon/getActive`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ pageData, ...(overwrites ? { overwrites } : {}) })
            })
            .then((response) => {
                if (response.status === 204) {
                    return null; // No coupon found
                } else if (response.ok || response.status === 200) {
                    return response.json();
                } else {
                    throw new Error('Coupon: Error while fetching active coupon.');
                }
            })
            .then((coupon) => {
                if (!coupon) {
                    eventData.fail();
                } else {
                    eventData.success({
                        longTitle: coupon.template.longTitle,
                        claim: coupon.template.claim,
                        claimMobile: coupon.template.claimMobile,
                        subClaim: coupon.template.subClaim,
                        subClaimAdd: coupon.template.subClaimAdd,
                        saving: coupon.template.saving,
                        currency: pageData.currency,
                        codeLabel: coupon.template.codeLabel,
                        code: coupon.template.code,
                        codeAvailable: coupon.template.codeAvailable,
                        countDown: coupon.template.countDown,
                        countDownExpire: {
                            date: '',
                            time: ''
                        },
                        terms: coupon.template.terms,
                        link: coupon.template.link
                    });
                }
            })
            .catch(eventData.fail);
    });

    /* Compatibility events END */

    /* TRACKING START */
    const handleTracking = ({ mastercode, linkLabel, variant, action, userInteraction }) => {
        window.utag.link({
            link_type: variant === 'S' ? 'mobile' : variant === 'M' ? 'flyin' : 'exit intent',
            link_category: 'promocode',
            link_action: action,
            link_label: `${linkLabel}${mastercode}`,
            noninteraction: !userInteraction
        });
    }

    window.addEventListener('tui-coupon.DisplayCoupon', ({ detail: { mastercode, linkLabel, variant, userInteraction } }) => {
        handleTracking({ mastercode, linkLabel, variant, action: 'display', userInteraction })
    });

    window.addEventListener('tui-coupon.CopyCode', ({ detail: { mastercode, linkLabel, variant, userInteraction } }) => {
        handleTracking({ mastercode, linkLabel, variant, action: 'copy', userInteraction })
    });

    window.addEventListener('tui-coupon.CopyCodeFailed', ({ detail: { mastercode, linkLabel, variant, userInteraction } }) =>{
        handleTracking({ mastercode, linkLabel, variant, action: 'copy failed', userInteraction })
    });

    window.addEventListener('tui-coupon.OpenCoupon', ({ detail: { mastercode, linkLabel, variant, userInteraction } }) => {
        handleTracking({ mastercode, linkLabel, variant, action: 'open', userInteraction })
    });

    window.addEventListener('tui-coupon.hyperlinkClicked', ({ detail: { mastercode, linkLabel, variant, userInteraction } }) => {
        handleTracking({ mastercode, linkLabel, variant, action: 'linkclick', userInteraction })
    });  

    /* 
    // BUG-21611 remove tracking for "no-coupon-available"
    window.addEventListener('tui-coupon.NoCouponAvailable', ({ detail }) => {
        window.utag.link({
            link_type: window.location.href,
            link_category: 'no-coupon-available',
            link_action: document.querySelector('.book-form') ? 'bu' : 'ibe',
            link_label: JSON.stringify(detail),
            noninteraction: false
        });
        // eslint-disable-next-line no-console
        console.warn('tui-coupon.NoCouponAvailable', detail);
    });
    */

    window.addEventListener('tui-coupon.ErrorFetchingCoupon', ({ detail }) => {
        window.utag.link({
            link_type: window.location.href,
            link_category: 'error-fetching-coupon',
            link_action: document.querySelector('.book-form') ? 'bu' : 'ibe',
            link_label: JSON.stringify(detail),
            noninteraction: false
        });
        // eslint-disable-next-line no-console
        console.warn('tui-coupon.ErrorFetchingCoupon', detail);
    });
    /* TRACKING END */

    window.tuiCottonBall.subscribe('tui-coupons', '*', 'Request page data.', (c, s, e, { callback }) => {
        if (typeof callback === 'function') {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeStateData) => {
                Promise.all([utagReady, pageReady]).then(() => callback(service.getPageData(ibeStateData)));
            });
        }
    });

    /* Start preview mode events 
       These events are fired by the coupon tool to change the data of the controller */
    window.addEventListener('message', ({ data }) => {
        if (data?.type === 'tui-coupon.PreviewMode' && data?.payload) {
            service.setPreviewModeData(data.payload);
            window.location.reload();
        }

        // Show exit intent
        if (data?.type === 'tui-coupon.PreviewMode.TriggerExitIntent') {
            document.querySelector('exit-intent-modal')?.setAttribute('display', true);
            window.localStorage.removeItem('exit-intent-modal-coupon');
            window.localStorage.removeItem('exit-intent-modal-auto-offer');
        }
    });
    /* End preview mode events */

    return 'tui-coupons';
}
